import { Observable, of } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { UserStorageService } from './user-storage.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { SchemaValidatorService } from '@shared/services/schema-validator.service';
import { LoginDtoSchema } from '@models/login.model';
import { KeepAliveService } from '@shared/services/keep-alive.service';
import { RuntimeConfigService } from '@shared/services/runtime-config.service';

interface SyntheseLoginParams {
    action: string;
    username: string;
    password: string;
}

interface SyntheseLoginResponse {
    sid: string;
    hasParametersPageAccess?: boolean;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
    #httpClient = inject(HttpClient);
    #keepAliveService = inject(KeepAliveService);
    #schemaValidator = inject(SchemaValidatorService);
    #userStorageService = inject(UserStorageService);
    #runtimeConfigService = inject(RuntimeConfigService);

    login(username: string, password: string): Observable<boolean> {
        const params: SyntheseLoginParams = {
            action: 'login',
            username,
            password,
        };

        // debug
        if (username === 'admin' && password === 'admin') {
            this.#userStorageService.saveUser({ username, sid: 'admin', hasParametersPageAccess: true });
            return of(true);
        }

        return this.#httpClient.post<SyntheseLoginResponse>(`${this.#runtimeConfigService.apiUrl()}/login`, params).pipe(
            map((data) => this.#schemaValidator.validate(LoginDtoSchema, data)),
            map(({ sid, hasParametersPageAccess }) => {
                if (sid !== '') {
                    this.#userStorageService.saveUser({ username, sid, hasParametersPageAccess });
                    this.#keepAliveService.startPolling();
                    return true;
                }
                return false;
            }),
        );
    }

    isAuthenticated(): boolean {
        return this.#userStorageService.getUser() !== null;
    }

    logout(): void {
        this.#userStorageService.removeUser();
        this.#keepAliveService.stopPolling();
    }

    hasParametersPageAccess(): boolean {
        const user = this.#userStorageService.getUser();
        return !!user && user.hasParametersPageAccess;
    }
}
