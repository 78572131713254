import { filter, fromEvent, merge, Observable, of, Subscription, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { inject, Injectable } from '@angular/core';
import { UserStorageService } from '@features/login/user-storage.service';
import { z } from 'zod';
import { Router } from '@angular/router';
import { SchemaValidatorService } from './schema-validator.service';
import { convertToIsoUtc } from '../utils/date-utils';
import { RuntimeConfigService } from './runtime-config.service';

const SuccessfulKeepAliveSchema = z.object({
    sid: z.string(),
    expirationDatetime: z.string().transform(convertToIsoUtc),
});

const ErrorKeepAliveSchema = z.object({
    success: z.boolean(),
    error: z.string(),
});

// Schéma combiné qui peut valider les deux types de réponses
const KeepAliveResponseSchema = z.union([SuccessfulKeepAliveSchema, ErrorKeepAliveSchema]);

type KeepAliveResponse = z.infer<typeof KeepAliveResponseSchema>;

@Injectable({ providedIn: 'root' })
export class KeepAliveService {
    #httpClient = inject(HttpClient);
    #userStorageService = inject(UserStorageService);
    #router = inject(Router);
    #schemaValidator = inject(SchemaValidatorService);
    #runtimeConfigService = inject(RuntimeConfigService);

    #lastKeepAliveTime = 0;
    readonly POLLING_INTERVAL = 5 * 60 * 1000;
    #pollingSubscription: Subscription | null = null;

    startPolling(): void {
        if (this.#pollingSubscription) this.stopPolling();

        const user = this.#userStorageService.getUser();
        if (!user?.sid) {
            console.error('No user SID found. Cannot start keepAlive polling.');
            this.#handleFailedKeepAlive();
            return;
        }

        const wakeUpEvents$ = merge(
            fromEvent(document, 'visibilitychange').pipe(filter(() => !document.hidden)),
            fromEvent(window, 'focus'),
            fromEvent(document, 'resume'),
        ).pipe(debounceTime(1000));

        const polling$ = merge(timer(0, this.POLLING_INTERVAL), wakeUpEvents$).pipe(
            switchMap(() => {
                const currentTime = Date.now();
                if (currentTime - this.#lastKeepAliveTime >= this.POLLING_INTERVAL) {
                    this.#lastKeepAliveTime = currentTime;
                    return this.#keepAlive();
                }
                return of(null);
            }),
            filter(Boolean),
            tap((response) => {
                if ('error' in response || ('success' in response && !response.success)) {
                    this.#handleFailedKeepAlive();
                }
            }),
        );

        this.#pollingSubscription = polling$.subscribe();
    }

    stopPolling(): void {
        this.#pollingSubscription?.unsubscribe();
        this.#pollingSubscription = null;
    }

    #handleFailedKeepAlive(): void {
        this.stopPolling();
        this.#userStorageService.removeUser();
        void this.#router.navigate(['/login']);
    }

    #keepAlive(): Observable<KeepAliveResponse> {
        return this.#httpClient.get<KeepAliveResponse>(`${this.#runtimeConfigService.apiUrl()}/keepAlive`).pipe(
            // mergeMap(response => {
            //     // Simuler une erreur 401 pour 50% des requêtes
            //     if (Math.random() < 0.5) {
            //         const errorResponse: z.infer<typeof ErrorKeepAliveSchema> = {
            //             success: false,
            //             error: 'Session expired'
            //         };
            //         return throwError(() => new HttpErrorResponse({
            //             error: errorResponse,
            //             status: 400,
            //             statusText: 'Bad Request'
            //         }));
            //     }
            //     return of(response);
            // }),
            map((data) => this.#schemaValidator.validate(KeepAliveResponseSchema, data)),
            catchError((error) => {
                const isErrorResponse = (err: unknown): err is z.infer<typeof ErrorKeepAliveSchema> => {
                    return typeof err === 'object' && err !== null && 'success' in err && 'error' in err;
                };
                /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */
                if (isErrorResponse(error.error)) {
                    /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */
                    return of(error.error as KeepAliveResponse);
                }
                console.error('KeepAlive error:', error);
                return of({ success: false, error: 'Network error' });
            }),
        );
    }
}
